import {Injectable} from '@angular/core';
import {BaseDirectory, createDir, exists, readBinaryFile, readDir, readTextFile} from '@tauri-apps/api/fs';
import {appDataDir, basename, extname, sep} from "@tauri-apps/api/path";

import {DomSanitizer} from '@angular/platform-browser';
import {open} from '@tauri-apps/api/shell';
import {fs} from "@tauri-apps/api";
import {FileObj, LocalStorageService} from "@customcoding/shared";
import {watch} from "tauri-plugin-fs-watch-api";
import {TauriService} from "./tauri.service";
import {metadata} from "tauri-plugin-fs-extra-api";
import {HttpClient} from "@angular/common/http";
import {platform} from '@tauri-apps/api/os';
import * as xml2js from 'xml-js';
import {PatientService} from "./patient.service";
import {Observable} from "rxjs";
import {Folder} from "../models/folder";
import moment from "moment";
import {root} from "rxjs/internal-compatibility";

interface LocalFile {
	path: string;
	createdAt?: Date;
	modifiedAt?: Date;
	downloadedAt?: Date;
	kind?: string; //status for CRUD,
	patient_id?: number;
	file_id?: number;
	contentType?: string;

}

@Injectable({
	providedIn: 'root'
})
export class TauriFilesystemService {


	downloadedFiles: LocalFile[] = [];
	uniquePaths;
	lastSyncedAt: Date;

	kindTypes = ["Any", "AnyContinuous"];

	constructor(
		private sanitizer: DomSanitizer,
		private tauri: TauriService,
		// public cloudService: ScapCloudService,
		private http: HttpClient,
		private storageService: LocalStorageService,
		private patientService: PatientService,
	) {
		console.log("tauri service init");
		this.uniquePaths = new Set<string>();
		this.lastSyncedAt = new Date(); //TODO --> we should save this variable inside the localStorage

		if (tauri.isDesktopApp()) {
			this.initFileWatcher();
			this.watchLabFolderChanges().then();

		}
	}

	/*
	this.initFileWatcher2();

		}

		initFileWatcher2(){

		 //   const { invoke } = require('@tauri-apps/api/call');

			const watcher = chokidar.watch('path/to/watched/files', {
				ignored: /(^|[\/\\])\../, // ignore dotfiles
				persistent: true,
			});

			watcher.on('all', (event,path) => {
				console.log("file changed, event = ",event);

				console.log("file changed, path = ",path);

			});


		}
	*/
	async initFileWatcher() {

		let basePath = await appDataDir();

		// can also watch an array of paths

		const stopWatching = await watch(
			basePath,
			(event: any) => {
				//  const { kind,path} = event[0];

				let kind;
				let path;


				let isIncluded = false;

				console.log("BASIC ___ file wachted event = ", event);
				//console.log("meta path = ",path);

				event.forEach((e) => {

					//|| this.kindTypes.includes(e.kind)
					if (this.uniquePaths.has(e.path)) {
						isIncluded = true;
						path = e.path;

					}
					;
					// console.log("path not existing or kind not matching!", {kind:kind, path:path});
				})

				if (!isIncluded) {

					console.log("path not existing or kind not matching!", event);

					return;
				}

				//check if file is really existing and ignore if not (workaround for tmp files e.g. MSWord)
				// this.checkPathExists(path).then(() =>{
				/*
				if(!this.uniquePaths.has(path) || !this.kindTypes.includes(kind)){
					console.log("path not existing or kind not matching!", {kind:kind, path:path});

					return;
				}

				 */


				metadata(path).then(meta => {

					console.log("meta: ", meta);
					this.updateDownloadedFileData({path: path, modifiedAt: meta.modifiedAt, createdAt: meta.createdAt});
				});

				//})

			},
			{recursive: true, delayMs: 2000},
		);

		//console.log("stopWathcing = ", stopWatching)

		/*
				const stopRawWatcher = await watchImmediate(
					[ basePath+"cloud"],
					(event) => {
						const { type, paths, attrs } = event;
						console.log("IMMEDIATE ___ file wachted event = ", event);
					},
					{recursive: true},
				);

		 */
	}


	/*
	async openFile() {

		console.log("testpath:", this.testPath);
		await open(this.testPath);
	}

	*/
	readXmlToJson(xml: string) {
		return JSON.parse(xml2js.xml2json(xml));
	}

	extractRegistrationNumberFromXml(xmlData: any) {
		return xmlData.elements
			.find((element: any) => element.type === 'element').elements[0]?.elements
			.find((bodyElement: any) => bodyElement.name === 'Header')?.elements
			.find((headerElement: any) => headerElement.name === 'SSNPatient').elements[0]?.text;
	}

	async getLaboratoryFolderLocalFiles() {
		// TODO: Assuming there is no nested folders
		const path = this.storageService.get('LAB_FOLDER_PATH');
		const entries = await readDir(path, {recursive: true});
		return entries.filter((entry) => entry.name.endsWith('.xml'));
	}

	getPatientFolderFiles(newerThan: string = null): Observable<any> {
		if (newerThan) {
			return this.http.get('/api/scap/patients/cloud/doctor-files?newer-than=' + newerThan);
		} else {
			return this.http.get('/api/scap/patients/cloud/doctor-files');
		}
	}

	async offlineSyncLabFolder() {
		let newerThan = this.storageService.get('filesLastChecked');
		if (!newerThan) {
			this.storageService.saveData('filesLastChecked', moment().format("YYYY-MM-DD HH:mm"));
		}

		const patientFiles = await this.getPatientFolderFiles(newerThan).toPromise();
		const labFolderFiles = await this.getLaboratoryFolderLocalFiles();

		const newlyUploadLabFiles = labFolderFiles.filter((labFile) => {
			const alreadyUploaded = patientFiles.find((patientFile: FileObj) => patientFile.originalName === labFile.name);
			return !alreadyUploaded;
		});
		if (newlyUploadLabFiles.length > 0) {
			for (const uploadedFile of newlyUploadLabFiles) {
				await this.uploadLabFiles(uploadedFile.path);
			}
		}
	}

	async watchLabFolderChanges() {
		const path = this.storageService.get('LAB_FOLDER_PATH');
		console.log('start watch for lab folder path', path);
		await watch(
			path,
			async (event) => {
				console.log({event});
				for (let i = 0; i < event.length; i++) {
					const {path: filePath} = event[i];
					const extension = await extname(filePath);
					console.log({extension});
					if (extension !== 'xml') {
						continue;
					}
					await this.uploadLabFiles(filePath);
				}
			},
			{recursive: true},
		);

	}

	async uploadLabFiles(filePath: string) {
		const rootFolder = this.storageService.get('LAB_FOLDER_PATH');
		const fileName = await basename(filePath);
		const isFileAdded = await exists(filePath);
		console.log({isFileAdded});
		if (isFileAdded) {
			const fileContent = await readTextFile(filePath);
			const fileContentToJson = this.readXmlToJson(fileContent);
			const registrationNumber = this.extractRegistrationNumberFromXml(fileContentToJson);
			console.log({registrationNumber});
			if (registrationNumber) {
				const patient = await (this.patientService.all({
					filters: {
						registration_number: registrationNumber,
					}
				})).toPromise();
				if (patient.length > 0) {
					// using http directly due to circular dependency
					const patientFolders = await (this.http.get<Folder[]>(`/api/scap/patient/${patient[0].id}/cloud`)).toPromise();
					// TODO: Find better way of checking for LABOR_<DOC_NAME>
					const doctorFolder = patientFolders.find((folder) => {
						const splitFolderName = folder.name.split('_');
						const isDefaultFolder = !isNaN(Number(splitFolderName[0]));
						return !isDefaultFolder;
					});
					const filePathWithoutRootSplit = filePath.split(rootFolder + '/');
					const filePathWithoutRoot = filePathWithoutRootSplit[1].split('/');
					console.log({filePathWithoutRoot});
					console.log({doctorFolder, filePath, filePathWithoutRootSplit});
					const doctorFirstName = doctorFolder.name.split('_')[0];
					const doctorLaboratoryFolder = doctorFolder.children
						.find((doctorFolderChild) => doctorFolderChild.key === `LABOR_${doctorFirstName}`);
					const contents = await readBinaryFile(filePath);
					const blob = new Blob([contents], {type: 'application/octet-stream'});
					const file = new File([blob], fileName, {type: 'application/octet-stream'});
					const formData = new FormData();
					formData.append('file', file);
					const fileExists = doctorLaboratoryFolder.files.find((docFile) => docFile.originalName === fileName);
					if (!fileExists) {
						let doctorLaboratoryFolderId = doctorLaboratoryFolder.id;
						if (filePathWithoutRoot[0] !== '') {
							//TODO: We are assuming it is only one folder after the root folder
							//TODO: i.e <Root>/Lab A/<xml or pdf file>
							const nestedFolder = filePathWithoutRoot[0];
							// 	we create the folder inside the doctor folder and get the folder id
							const payload = {
								name: nestedFolder,
								parentFolder_id: doctorLaboratoryFolderId,
							};
							const nestedFolderResponse: any = await (this.http.post(`/api/scap/patient/${patient[0].id}/cloud/folder`, payload))
								.toPromise();
							doctorLaboratoryFolderId = nestedFolderResponse.id;
							//TODO: Need for setting permissions with limited roles?
						}
						await (this.http.post(`/api/scap/patient/${patient[0].id}/cloud/folder/${doctorLaboratoryFolderId}/file`, formData))
							.toPromise();
					} else {
						formData.append('_method', 'PATCH');
						await (this.http
								.post(`/api/scap/patient/${patient[0].id}/cloud/file/${fileExists.id}`, formData)
						).toPromise();
					}
					console.log({patient, doctorLaboratoryFolder, doctorFolder});
				}
			}
		}
	}

	async downloadBlobFile(blob, file: FileObj, patient_id) {

		const platformName = await platform();
		console.log("plattoform = ", platformName);
		console.log("sep:", sep);

		let basePath = await appDataDir();
		let filename = file.originalName; //encodeURIComponent(file.originalName);
		//  console.log("filename ==", filename);
		let path = `${basePath}cloud${sep}${file.id}${sep}${filename}`;
		const contents = await blob.arrayBuffer();


		let folderExisting = await this.checkExists(`cloud${sep}${file.id}`);
		//console.log("existing? ", folderExisting);
		if (!folderExisting) {
			//console.log("create folder with id = ", file.id);
			await this.createFolder(`cloud${sep}${file.id}`)
		} else {
			// console.log("folder existing")
		}

		//  console.log("write file...");

		await fs.writeBinaryFile(`cloud${sep}${file.id}${sep}${file.originalName}`, contents, {dir: BaseDirectory.AppData});

		//console.log("lalal");
		//console.log( patient_id);
		//console.log("open file path = " , path);
		open(path).then(() => {
			this.addOrIgnore({
				path: path,
				modifiedAt: null,
				createdAt: null,
				downloadedAt: new Date(),
				file_id: file.id,
				patient_id: patient_id,
				contentType: file.mimeType
			});

			console.log("all uniqueParts: ");
			console.log(this.uniquePaths);
		});

	};

	async getFilesForUpload() {

		console.log("currentRef Date = ", this.lastSyncedAt);

		let filesForUpload = this.downloadedFiles.filter((f) => f.modifiedAt > this.lastSyncedAt && f.createdAt != null);

		console.log("files, ready for update: ", filesForUpload);

		//TODO: upload eligible files
		filesForUpload.forEach((f) => {


			//old:            // const fileName = f.path.match(/\/([^/]+)$/)?.[1] || "";
			const fileName = f.path.match(/[\\\/]([^\\\/]+)$/)?.[1] || "";
			console.log("filename :::::", fileName);

			setTimeout(() => {
				console.log("filename before reading from disk: ", fileName);

				//get the file from the file system:
				readBinaryFile(`cloud${sep}${f.file_id}${sep}${fileName}`, {dir: BaseDirectory.AppData}).then((localFile) => {


//                const blob = base64ToBlob(localFile);
					//  console.log("tyoeof Localfile123 = ", typeof  localFile);
					//console.log(localFile);


					this.getBlob(localFile, f).then((blob) => {
						//  console.log("blob file = ");
						//  console.log(blob);


						const formdata = new FormData();
						formdata.append('file', blob, fileName);
						//formdata.append("storedFileName",fileName);

						formdata.append("_method", "patch");
						console.log(formdata);
						/*
										  let folderId = 11;

										  return this.http.post<string>(`/api/scap/patient/${f.patient_id}/cloud/folder/${folderId}/file`, formdata).subscribe((result) => {


										  });
						*/

						this.http.post(`/api/scap/patient/${f.patient_id}/cloud/file/${f.file_id}`, formdata).subscribe((result) => {
							console.log("result after upload", result);
							//  f.createdAt = null;
						});

						//   console.log("TODO: upload file with id", f.file_id);
						//f.createdAt = null;
					});


					//  this.cloudService.updateCloudFile(f.patient_id,f.file_id,localFile);
				});

			}, 1000);

			console.log("all downloaded files = ");
			console.log(this.downloadedFiles);
			//update ref date:
			this.lastSyncedAt = new Date();

		});

	}

	async getBlob(localFile: any, f): Promise<Blob> {

		console.log("typeof localFile = ", typeof localFile)
		// ArrayBuffer -> Blob
		//letvar uint8Array  = new Uint8Array([1, 2, 3]);
		let arrayBuffer = localFile.buffer;
		let blob = new Blob([arrayBuffer], {type: f.contentType});

		return blob;
		/*
		const blobAgain = new Blob(

			[new Buffer(localFile.toString(), 'base64')],
			{
				type: f.contentType
			}
		);
		const str = await blobAgain.text();
		console.log("str:::",str);
return blobAgain;
*/
		//   let buffer = new ArrayBuffer(32);
		// return new Blob([localFile].buffer, {type: f.contentType});
	}

	addOrIgnore(file: LocalFile) {

		//add if not there:
		if (!this.uniquePaths.has(file.path)) {
			// Value is not a duplicate, add it to the array and the Set
			this.uniquePaths.add(file.path);
			this.downloadedFiles.push(file);
		} else {
			let index = this.downloadedFiles.findIndex(f => f.path = file.path);
			this.downloadedFiles[index].createdAt = null;
			//check the last modified date and update if needed:
			// if(file.downloadedAt >)

		}
	}

	updateDownloadedFileData(file: LocalFile) {

		//console.log("watched file = " , file);
		//console.log("downloadedFiles before:", this.downloadedFiles);

		if (!this.uniquePaths.has(file.path)) {
			//TODO: the first case is only possible if the file was added manually or if the data is not correct syncrhoniced before

		} else {
			const resultIndex = this.downloadedFiles.findIndex(f => file.path == f.path);
			// console.log("resultIndex: ", resultIndex);
			if (resultIndex == -1)
				return;

			let result = this.downloadedFiles[resultIndex];
			if (result.createdAt == null) {
				console.log("createAt is null");
				result.modifiedAt = file.modifiedAt;
				result.createdAt = file.createdAt;//set modifiedAt + createdAt
				this.downloadedFiles[resultIndex] = result;

				return; //abort, because it's called directly after file creation

			} else {
				console.log("else case!")
				result.modifiedAt = file.modifiedAt; //update only modifiedAt
				this.downloadedFiles[resultIndex] = result;
			}

			console.log("downloadedFiles after:", this.downloadedFiles);

			//TODO: maybe its better to run this function periodically e.g. each 1 minute, to check for all files, changed between the ref date and the modified date
			this.getFilesForUpload();
		}

	}

	/*
		downloadFile(url: string) {

			console.log("download file (tauri)");

			//let url = "https://www.rust-lang.org/logos/rust-logo-512x512.png";
			appDataDir().then(path => {

				console.log("path::", path);


				invoke('downloadFile', {url: url, destination: path + 'cloud'}).then((response) => {
					const fileName = url.match(/\/([^/]+)$/)?.[1] || "";

					console.log("filename test:", fileName);

				   // this.testPath = path + 'cloud/' + fileName;
					//this.openFile();
					console.log("response:::", response);
				})
			})

		}
	*/


	/* TESTING + PLAYGROUND */

	async checkExists(name) {

		return await exists(name, {dir: BaseDirectory.AppData});

	}

	async checkPathExists(name) {

		return await exists(name);

	}

	async createFolder(name: string = "tauri_test_folder") {
		await createDir(name, {dir: BaseDirectory.AppData, recursive: true});
		//  await createDir(name, { dir: BaseDirectory.Download, recursive: true });

	}

	async readFolderContent(name: string = 'cloud') {

		const entries = await readDir(name, {dir: BaseDirectory.AppData, recursive: true});
		console.log("-----start list content:")
		this.processEntries(entries);
		console.log("-----end list content:")

	}

	processEntries(entries) {
		for (const entry of entries) {
			console.log(`Entry: ${entry.path}`);
			if (entry.children) {
				this.processEntries(entry.children)
			}
		}
	}

}
